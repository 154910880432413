<template>
  <li class="sidebar-dropdown-item">
    <div>
      <span class="item-row drop" @click="toggleDrop">
        <slot name="icon"></slot>
        <i v-if="iconClass" :class="iconClass" class="icon"></i>
        <label>{{ label }}</label>
      </span>
      <div class="dropdown">
        <slot></slot>
      </div>
    </div>
  </li>
</template>

<script>
/**
 * SideSideBarItem that has a dropdown. It should contain SideBarItem and
 * displays them when dropdown is clicked.
 *
 * @iconClass class used for the icon.
 * @label the display name for the item.
 */

import "./style/sidebar.scss";

export default {
  components: {},
  mixins: [],
  props: {
    label: {
      type: String,
      required: true
    },
    iconClass: {
      type: String,
      default: ""
    }
  },
  data: function () {
    return {};
  },
  computed: {
    isMenuOpen() {
      return this.$store.getters.getSidebarState;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    toggleDrop(e) {
      if (this.isMenuOpen) {
        e.currentTarget.classList.toggle("active");
        $(e.currentTarget).siblings(".dropdown").slideToggle(300);
      } else {
        this.$store.commit("toggleSidebar");
      }
    }
  }
};
</script>

<style lang="scss"></style>
